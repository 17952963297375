<template>
  <div>
    <div class="container">
      <!-- 图片 -->
      <div class="left">
          <img src="@/assets/epx-logo.png" alt="" style="width: 5.75rem;"  v-show="showLeft" >
      </div>
      <!-- 文字 -->
      <div class="center" v-show="showCenter">{{title}}</div>
      <!-- 导航 -->
      <div class="right" @click="showPanel">
          <van-icon name="cross" size="24" color="#444" v-if="isShow"/>
          <van-icon name="wap-nav" size="24" color="#444" v-else/>
      </div>
    </div>

    <!-- 导航内容 -->
    <div class="panel" v-if="isShow">
      <div class="collapse">
        <div class="collapseList" v-for="item in panelList" :key="item.name" :style="{border:activeName===item.name?'0':''}">
          <!-- 一级导航 -->
          <div class="collapseListTitle" @click.stop="clickPanel(item)">
            <span :class="{activeColor:item.path&&item.path===$route.fullPath}">{{item.title}}</span>
            <van-icon :name="activeName===item.name?'arrow-up':'arrow-down'" @click.stop="clickPanel(item)" v-if="item.children"/>
          </div>
          <!-- 二级导航 -->
          <div class="collapseListContent" v-if="activeName===item.name">
            <div :class="desc.childList?'collapseListDescHaveChild':'collapseListDesc'" v-for="(desc,index) in item.children" :key="index">
              <!-- 二级标题 -->
              <span :class="{activeColor:desc.path&&desc.path===$route.fullPath,childSpanTitle:desc.childList}" @click="goPage(desc.path)" style="display:inline-block;width:100%">{{desc.childTitle}}</span>
              <!-- 二级子内容 -->
              <div v-if="desc.childList" class="childrenContentList">
                <div v-for="(content,i) in desc.childList" :key="i" :class="{moChildrenContentItem:true,activeColor:content.path===$route.fullPath}" @click="goPage(content.path)">{{content.listTitle}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoTitle',
  props: {
    showLeft: {
      type: Boolean,
      default: true
    },
    showCenter: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isShow: false,
      activeName: null,
      panelList: [
        {
          title: '首页',
          name: 0,
          path: '/mohome'
        },
        {
          title: '产品服务',
          name: 1,
          children: [
            {
              childTitle: '基础平台',
              childList: [
                {
                  listTitle: '低代码开发平台',
                  path: '/moProduct/moPT'
                },
                {
                  listTitle: '智慧人事服务大厅',
                  path: '/moProduct/moSmartDT'
                },
                {
                  listTitle: '人力资源数据中心',
                  path: '/moProduct/moDataCenter'
                }
              ]
            },
            {
              childTitle: '业务系统',
              childList: [
                {
                  listTitle: '招聘管理系统',
                  path: '/moProduct/moEmployment'
                },
                {
                  listTitle: '基础人事系统',
                  path: '/moProduct/moBasePeople'
                },
                {
                  listTitle: '师资培养与发展系统',
                  path: '/moProduct/moProductSzpyyfzxt'
                },
                {
                  listTitle: '薪酬福利管理系统',
                  path: '/moProduct/moPaySystem'
                },
                {
                  listTitle: '岗位评聘系统',
                  path: '/moProduct/moPosition'
                },
                {
                  listTitle: '考核与评估系统',
                  path: '/moProduct/moProductKhypg'
                },
                {
                  listTitle: '高层次人才管理系统',
                  path: '/moProduct/moProductHeight'
                },
                {
                  listTitle: '博士后管理系统',
                  path: '/moProduct/moProductBsh'
                },
                {
                  listTitle: '学者论坛管理系统',
                  path: '/moProduct/moProductXzlt'
                },
                {
                  listTitle: '投票表决系统',
                  path: '/moProduct/moVote'
                },
                {
                  listTitle: '目标责任制考核系统',
                  path: '/moProduct/moProductMbzrz'
                },
                {
                  listTitle: '组干与党务管理系统',
                  path: '/moProduct/moProductZgydwgl'
                },

                {
                  listTitle: '离退休系统',
                  path: '/moProduct/moRetire'
                },

                {
                  listTitle: '统战管理系统',
                  path: '/moProduct/moUnite'
                },
                {
                  listTitle: '自助打印系统',
                  path: '/moProduct/moPrint'
                },

                {
                  listTitle: '人力资源决策分析系统',
                  path: '/moProduct/moPeer'
                }

              ]
            },
            {
              childTitle: '服务',
              childList: [
                {
                  listTitle: '同行专家评审系统',
                  path: '/moProduct/moProductThps'
                },
                {
                  listTitle: '人事人才培训服务',
                  path: '/moProduct/moProductRsrcpxfw'
                },
                {
                  listTitle: '应用开发服务',
                  path: '/moProduct/moProductYykffw'
                }
              ]

            }
          ]
        },
        {
          title: '解决方案',
          name: 2,
          children: [
            {
              childTitle: '高校智慧人事',
              path: '/moSlution/moSchool'
            },
            {
              childTitle: '医院智慧人事',
              path: '/moSlution/moSmartYy'
            },
            {
              childTitle: '高校后勤人事',
              path: '/moSlution/moSmartSchoolLoHr'
            },
            {
              childTitle: '岗位目标动态监测',
              path: '/moSlution/moSmartXyyjcg'
            },
            {
              childTitle: '学术能力智慧评估',
              path: '/moSlution/moAssess'
            }
          ]
        },
        {
          title: '经典案例',
          name: 3,
          path: '/moCooperation/moTypicalcase'
          // children: [
          //   {
          //     childTitle: '典型客户',
          //     path: '/moCooperation/moCustomer'
          //   },
          //   {
          //     childTitle: '经典案例',
          //     path: '/moCooperation/moTypicalcase'
          //   }
          // ]
        },
        {
          title: '新闻动态',
          name: 4,
          path: '/moNews'
        },
        {
          title: '关于我们',
          name: 5,
          path: '/moAbout'
        },
        {
          title: '联系我们',
          name: 6,
          path: '/moRelationUs'
        }
      ]
    }
  },
  methods: {
    showPanel () {
      this.isShow = !this.isShow
      if (!this.isShow) {
        this.activeName = null
      }
      this.$emit('moTitltFn', this.isShow)
    },
    clickPanel (item) {
      if (item.children) {
        if (this.activeName && this.activeName === item.name) {
          this.activeName = null
        } else {
          this.activeName = item.name
        }
      } else {
        this.$router.push(item.path)
      }
    },
    goPage (path) {
      if (path) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style scoped>
body{
  overflow: hidden;
height: 100%;
}
.activeColor{
color: #D70C0D;
}
.container{
    position: fixed;
    width: 100%;
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid rgba(211, 218, 228, 0.5);;
    z-index: 100;
      overflow-x:hidden;
  overflow-y:hidden;
}
.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.left{
  height: 100%;
  display: flex;
  align-items: center;
}

.panel{
    top: 2.75rem;
  bottom: 3.75rem;
  position: fixed;
  width: 100%;
  /* min-height: 100%; */
  background-color: #fff;
  z-index: 100;
  padding: 0 20px;
  overflow-x:hidden;
  overflow-y:scroll;
}
.collapseList{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.collapseListTitle{
  padding:.875rem .625rem;
font-size: 1rem;
font-weight: 400;
color: #222222;
line-height: 1.375rem;
display: flex;
justify-content: space-between;
align-items: center;

}
.collapseListContent{
  background-color: #F5F7FA;
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
}

.collapseListDesc{
  padding: .875rem 2.5rem;
  border-bottom: .0625rem solid rgba(0, 0, 0, 0.1);;
}
.collapseListDescHaveChild{
  padding:20px 10px;
}
.childSpanTitle{
font-size: 1rem;
font-weight: 550;
color: #222222;
}
.childrenContentList{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
}
.moChildrenContentItem{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 2.5rem;
  text-align: center;
  /* line-height: 2.5rem; */
  margin-top: 14px;
  background-color: #fff;
  font-size: 14px;
}
</style>
